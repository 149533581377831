import Image from 'next/image'
import type React from 'react'
import styled from 'styled-components'

import type { Product } from '../../generated/graphql'
import theme from '../../theme'
import Media from '../../utils/Media'
import { Button } from '../elements/Button'
import { Link } from '../elements/Link'
import { hoverOpacity } from '../utils/hoverOpacity'

const PackageCard: React.FC<{
  product: Pick<Product, 'id' | 'coverImages' | 'name' | 'tagline'>
}> = ({ product }) => {
  const detailUrl = `/products/${product.id}`
  return (
    <Card>
      {product.coverImages.length > 0 && (
        <Link href={detailUrl} passHref>
          <CardImageLink>
            <CardImage
              src={product.coverImages[0].url}
              alt={product.coverImages[0].text}
            />
          </CardImageLink>
        </Link>
      )}
      <Detail>
        <DetailTitle>{product.name}</DetailTitle>
        <DetailDescription>{product.tagline}</DetailDescription>
        <ButtonWrapper>
          <Link href={detailUrl} passHref>
            <StyledButton>詳細を見る</StyledButton>
          </Link>
          <Link href="/#appDownload" passHref>
            <StyledButton>ダウンロードして購入する</StyledButton>
          </Link>
        </ButtonWrapper>
      </Detail>
    </Card>
  )
}

const CardImage: React.FC<{
  src: string
  alt: string
}> = ({ src, alt }) => (
  <ImageWrapper>
    <Image src={src} alt={alt} fill style={{ objectFit: 'cover' }} />
  </ImageWrapper>
)

const ImageWrapper = styled.div`
  position: relative;
  aspect-ratio: 372/209;
`

const DetailTitle = styled.h3`
  display: flex;
  align-items: center;
  color: ${theme.colors.textBlackPrimary};
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;

  &:before {
    content: url(/svgs/ticket.svg);
    display: inline-block;
    width: 48px;
    height: 48px;
    margin-right: 16px;
  }

  ${Media.mobileTablet} {
    font-size: 16px;
    line-height: 25px;
  }
`

const DetailDescription = styled.p`
  flex: 1;
  margin-top: 24px;
  ${theme.typography.Headline6}
  line-height: 1.5;

  ${Media.mobile} {
    margin-top: 16px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  padding-top: 24px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 4px;
    background-image: linear-gradient(
      to right,
      rgba(229, 229, 229, 1),
      rgba(229, 229, 229, 1) 4px,
      transparent 4px,
      transparent 16px
    );
    background-repeat: repeat-x;
    background-size: 16px 4px;
  }

  ${Media.mobile} {
    margin-top: 16px;
    padding-top: 20px;
  }
`

const Detail = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 24px 22px;

  ${Media.mobile} {
    padding: 16px 20px;
  }
`

const StyledButton = styled(Button)`
  width: 100%;
  min-height: 48px;
  cursor: pointer;

  ${Media.mobileTablet} {
    width: 100%;
    max-width: 315px;
    min-height: 40px;
    padding-top: 9px;
    padding-bottom: 9px;
    font-size: 14px;
    line-height: 16px;
  }

  &:last-child {
    margin-top: 8px;
  }
`

const CardImageLink = styled.a`
  ${hoverOpacity()}
  cursor: pointer;
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
`

export { PackageCard }
