import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import type React from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import useMedia from 'use-media'

import theme from '../../../theme'
import Media, { MOBILE_WIDTH } from '../../../utils/Media'
import { Button } from '../../elements/Button'
import { Container } from '../../organisms/Container'
import { Grid } from '../../organisms/Grid'
import { PackageCard } from '../../organisms/PackageCard'
import { hoverOpacity } from '../../utils/hoverOpacity'
import type { ProductModel } from '../ProductsTemplate/models'

type Props = {
  products: ProductModel[]
}

const PackagesSlider: React.FC<Props> = ({ products }) => {
  const isMobile = useMedia({ maxWidth: MOBILE_WIDTH })
  if (isMobile) {
    return <SpPackagesSlider products={products} />
  }
  return <PcPackagesSlider products={products} />
}

const SpPackagesSlider: React.FC<Props> = ({ products }) => (
  <Container>
    <Grid by={1}>
      {products.map((product) => {
        if (product !== null) {
          return (
            <GridItem key={product.id}>
              <PackageCard product={product} />
            </GridItem>
          )
        }
        // biome-ignore lint/correctness/useJsxKeyInIterable: 空要素のfragmentなのでkey不要
        return <></>
      })}
    </Grid>
    <ProductsButtonWrapper>
      <StyledProductsButton href="/products">
        全ての商品を見る
      </StyledProductsButton>
    </ProductsButtonWrapper>
  </Container>
)

const PcPackagesSlider: React.FC<Props> = ({ products }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 7000,
        settings: {
          centerPadding: '200px',
          slidesToShow: Math.min(7, products.length),
        },
      },
      {
        breakpoint: 3000,
        settings: {
          centerPadding: '200px',
          slidesToShow: Math.min(5, products.length),
        },
      },
      {
        breakpoint: 2000,
        settings: {
          centerPadding: '150px',
          slidesToShow: Math.min(3, products.length),
        },
      },
      {
        breakpoint: 1440,
        settings: {
          centerPadding: '45px',
          slidesToShow: Math.min(3, products.length),
        },
      },
      {
        breakpoint: 1200,
        settings: {
          centerPadding: '300px',
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          centerPadding: '200px',
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          centerPadding: '150px',
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 650,
        settings: {
          centerPadding: '100px',
          slidesToShow: 1,
        },
      },
    ],
  }
  return (
    <>
      <StyledSlider {...settings}>
        {products.map((product) => {
          if (product !== null) {
            return (
              <PackageWrapper key={product.id}>
                <PackageCard product={product} />
              </PackageWrapper>
            )
          }
          // biome-ignore lint/correctness/useJsxKeyInIterable: 空要素のfragmentなのでkey不要
          return <></>
        })}
      </StyledSlider>
      <ProductsButtonWrapper>
        <StyledProductsButton href="/products">
          全ての商品を見る
        </StyledProductsButton>
      </ProductsButtonWrapper>
    </>
  )
}

const NextArrow = styled.div`
  position: absolute;
  right: 50px;
  cursor: pointer;
  ${hoverOpacity()}

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: -18px;
    width: 54px;
    height: 54px;
    margin-top: -27px;
    border-radius: 50%;
    background: black;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: -8px;
    width: 18px;
    height: 18px;
    margin-top: -12px;
    transform: rotate(45deg);
    border: 0;
    border-top: solid 6px white;
    border-right: solid 6px white;
  }
`

const PrevArrow = styled.div`
  position: absolute;
  z-index: 100;
  left: 50px;
  cursor: pointer;
  ${hoverOpacity()}

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: -18px;
    width: 54px;
    height: 54px;
    margin-top: -27px;
    border-radius: 50%;
    background: black;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 18px;
    height: 18px;
    margin-top: -12px;
    transform: rotate(225deg);
    border: 0;
    border-top: solid 6px white;
    border-right: solid 6px white;
  }
`

const GridItem = styled.li`
  margin-bottom: 24px;

  &:last-child {
    margin: 0;
  }
`

const StyledSlider = styled(Slider)`
  * {
    outline: none;
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: auto;

    & > div {
      height: calc(100% - 6px);
    }
  }
`

const PackageWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 20px;
`

const ProductsButtonWrapper = styled.div`
  text-align: center;

  ${Media.desktop} {
    margin-top: 64px;
  }

  ${Media.mobileTablet} {
    margin-top: 32px;
  }
`

const StyledProductsButton = styled(Button)`
  background: ${theme.colors.white};

  ${Media.tabletDesktop} {
    min-width: 372px;
  }

  ${Media.mobile} {
    width: 100%;
    max-width: 315px;
    height: 56px;
  }
`

export { PackagesSlider }
