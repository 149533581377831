import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Image from 'next/image'
import type React from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import useMedia from 'use-media'

import theme from '../../../theme'
import Media, { MOBILE_WIDTH } from '../../../utils/Media'

const HeroSlider: React.FC = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    pauseOnHover: false,
    slidesToShow: 1,
    fade: true,
  }
  const images = [
    '/images/hero1.jpg',
    '/images/hero2.jpg',
    '/images/hero3.jpg',
    '/images/hero4.jpg',
  ]
  return (
    <StyledDiv>
      <Background>
        <LogoWrapper>
          <Text>日本全国乗り放題</Text>
        </LogoWrapper>
      </Background>
      <StyledSlider {...settings}>
        {images.map((image) => (
          <SliderItem key={image}>
            <SliderImage src={image} alt={image} width={1440} height={810} />
          </SliderItem>
        ))}
      </StyledSlider>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  position: relative;
`

const Text = styled.h3`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
  color: ${theme.colors.textWhitePrimary};
  font-size: 40px;
  font-weight: 700;
  line-height: 1;

  &:before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 79px;
    margin-bottom: 20px;
    background-image: url(/svgs/heroLogoText.svg);
    background-size: contain;
    line-height: 1;
    vertical-align: middle;
  }

  ${Media.mobileTablet} {
    margin-top: 25px;
    font-size: 18px;
    line-height: 22.5px;

    &:before {
      width: 238px;
      height: 46px;
      margin-bottom: 10px;
    }
  }
`

const LogoWrapper = styled.div`
  width: 410px;
  text-align: center;

  &:before {
    content: '';
    display: inline-block;
    width: 160px;
    height: 160px;
    background-image: url(/svgs/heroLogo.svg);
    background-size: contain;
    vertical-align: middle;
  }

  ${Media.mobileTablet} {
    &:before {
      width: 100px;
      height: 100px;
    }
  }
`

const SliderItem = styled.div`
  position: relative;
`

const Background = styled.div`
  display: flex;
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
`

const SliderImage: React.FC<{
  src: string
  alt: string
  height: number
  width: number
}> = ({ src, alt, height, width }) => {
  const isMobile = useMedia({ maxWidth: MOBILE_WIDTH })
  const aspectRatio = isMobile ? '375/632' : `${width}/${height}`

  return (
    <ImageWrapper aspectRatio={aspectRatio}>
      <Image src={src} alt={alt} fill style={{ objectFit: 'cover' }} />
    </ImageWrapper>
  )
}

const ImageWrapper = styled.div<{ aspectRatio: string }>`
  position: relative;
  aspect-ratio: ${(props) => props.aspectRatio};
`

const StyledSlider = styled(Slider)`
  * {
    outline: none;
  }
`

export { HeroSlider }
