import type React from 'react'
import styled from 'styled-components'

import theme from '../../../theme'
import { Title } from '../../elements/Title'
import { AppDownloadSection } from '../../organisms/AppDownloadSection'
import { Layout } from '../../organisms/Layout'
import { ScrollToSection } from '../../organisms/Section'
import type { ProductModel } from '../ProductsTemplate/models'
import { AboutSection } from './AboutSection'
import { BusinnessContactSection } from './BusinnessContactSection'
import { HeroSlider } from './HeroSlider'
import { HowToSection } from './HowToSection'
import { PackagesSlider } from './PackagesSlider'

type Props = {
  products: ProductModel[]
}

const IndexTemplate: React.FC<Props> = ({ products }) => (
  <Layout>
    {/* トップ画像 セクション */}
    <section>
      <HeroSlider />
    </section>
    {/* 販売中の商品 セクション */}
    <StyledSection id="products">
      <Title padded>おすすめの商品</Title>
      <PackagesSlider products={products} />
    </StyledSection>
    {/* RYDE PASSについて セクション */}
    <AboutSection />
    {/* 使い方 セクション */}
    <HowToSection />
    {/* アプリダウンロード セクション */}
    <AppDownloadSection />
    {/* お問い合わせ セクション */}
    <BusinnessContactSection />
  </Layout>
)

const StyledSection = styled(ScrollToSection)`
  background: ${theme.colors.bgThinGray};
`

export { IndexTemplate }
