import Image from 'next/image'
import type React from 'react'
import styled from 'styled-components'

import theme from '../../../theme'
import Media from '../../../utils/Media'
import { Title } from '../../elements/Title'
import { Container } from '../../organisms/Container'
import { Grid } from '../../organisms/Grid'
import { ScrollToSection } from '../../organisms/Section'

const AboutSection: React.FC = () => (
  <ScrollToSection id="about">
    <Title padded>RYDE PASSについて</Title>
    <StyledContainer>
      <Grid by={1} tablet={1} desktop={2}>
        <GridItem>
          <StyledImage
            src="/svgs/pass.svg"
            alt="RYDE PASS"
            width={530}
            height={400}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </GridItem>
        <GridItem>
          <HowToTitle>
            ずっと紙だったきっぷ・乗車券がアプリで購入＆使えるようになりました！
          </HowToTitle>
          <Description>
            乗り物を降りる時に乗車券を探した事はありませんか？
            <br />
            RYDE
            PASSを利用すれば紛失の心配や、焦ってバッグやポケットを探す手間がなくなります。
            <br />
            ※乗車券追加のご要望はお問い合わせよりご連絡ください。
          </Description>
        </GridItem>
      </Grid>
    </StyledContainer>
  </ScrollToSection>
)

const StyledContainer = styled(Container)`
  max-width: 1100px;

  ${Media.mobileTablet} {
    margin-top: 32px;
  }
`

const GridItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const HowToTitle = styled.h3`
  display: inline-block;
  color: ${theme.colors.textBlackPrimary};
  font-size: 30px;
  font-weight: 700;
  line-height: 37.5px;
  vertical-align: top;

  ${Media.mobileTablet} {
    font-size: 18px;
    line-height: 22.5px;
  }
`

const Description = styled.p`
  ${theme.typography.Headline2}
  line-height: 35px;

  ${Media.mobileTablet} {
    margin-top: 24px;
    font-size: 16px;
    line-height: 28px;
  }

  ${Media.desktop} {
    max-width: 475px;
    margin-top: 32px;
  }
`

const StyledImage = styled(Image)`
  width: 100%;
  height: 200px;
`

export { AboutSection }
